<template>
  <b-container>
    <div class="hello">
      <h1 class="display-4">{{ msg }}</h1>
    </div>
    <b-row align-h="center">
      <b-col col md="auto">
        <b-input-group prepend="Servidor:">
          <b-form-select
            id="select-server"
            v-model="selectedServer"
            :options="options"
            @change="getUrl(selectedServer)"
          >
          </b-form-select>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="py-2">
      <b-col>
        <b-tabs
          v-model="tabIndex"
          content-class="col-12 mt-3"
          align="center"
          lazy
        >
          <b-tab title="Indicadores" @click.prevent="getTab(0)">
            <b-row class="justify-content-center">
              <b-col class="col-auto">
                <b-table
                  show-empty
                  stripped
                  hover
                  bordered
                  stacked="md"
                  :items="items"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  head-variant="dark"
                >
                  <template v-slot:head(action)>
                    <b-input-group>
                      <b-form-select
                        id="select-anyos"
                        v-model="selected"
                        :options="anyos"
                        @change="getIndicadoresByYear(selected)"
                        size="sm"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >--</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                      <b-input-group-append>
                        <b-button size="sm" text="Button" variant="primary"
                          >Nuevo</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </template>
                  <template v-slot:cell(action)="row">
                    <b-button
                      class="mr-1"
                      variant="info"
                      @click="showIndic(row.item, row.index, $event.target)"
                      >Editar</b-button
                    >
                    <b-button
                      class="mr-1"
                      variant="danger"
                      @click="elimIndic(row.item)"
                      >&#10007;</b-button
                    >
                  </template>
                </b-table>
                <b-modal
                  :id="infoModal.id"
                  :title="infoModal.title"
                  @ok="handleGuardarIndic"
                  @hide="resetInfoModal"
                  ok-title="Guardar"
                  ok-variant="success"
                  cancel-title="Cancelar"
                >
                  <b-form-row>
                    <b-form-group
                      class="col-6 px-3"
                      id="id-group-mes"
                      label="Mes"
                      label-for="wi_month"
                    >
                      <b-form-select
                        id="wi_month"
                        v-model="selectedMonth"
                        type="number"
                        :options="meses"
                        required
                        placeholder="Mes"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      class="col-6"
                      id="id-group-anyo"
                      label="Año"
                      label-for="wi_year"
                    >
                      <b-form-select
                        id="wi_year"
                        v-model="selectedYear"
                        type="number"
                        :options="anyos"
                        required
                        placeholder="Año"
                      ></b-form-select>
                    </b-form-group>
                  </b-form-row>
                  <b-form-row>
                    <b-form-group
                      class="col-6 px-3"
                      id="id-group-uf"
                      label="UF"
                      label-for="wi_uf"
                    >
                      <b-input
                        id="wi_uf"
                        v-model="infoModal.content.wi_uf"
                        type="number"
                        required
                        placeholder="UF"
                      ></b-input>
                    </b-form-group>
                    <b-form-group
                      class="col-6"
                      id="id-group-utm"
                      label="UTM"
                      label-for="wi_utm"
                    >
                      <b-input
                        id="wi_utm"
                        v-model="infoModal.content.wi_utm"
                        type="number"
                        required
                        placeholder="UTM"
                      ></b-input>
                    </b-form-group>
                  </b-form-row>
                </b-modal>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Afps" @click.prevent="getTab(1)">
            <b-row class="justify-content-center">
              <b-col class="col-auto">
                <b-table
                  show-empty
                  stripped
                  hover
                  bordered
                  stacked="md"
                  :items="items2"
                  :fields="fields2"
                  :sort-by.sync="sortBy"
                  head-variant="dark"
                >
                  <template v-slot:head(action)>
                    <b-button size="sm" text="Button" variant="primary"
                      >Nuevo</b-button
                    >
                    <b-input-group>
                      <b-input-group-append> </b-input-group-append>
                    </b-input-group>
                  </template>
                  <template v-slot:cell(action)="row">
                    <b-button
                      class="mr-1"
                      variant="info"
                      @click="showAfp(row.item, row.index, $event.target)"
                      >Editar</b-button
                    >
                    <b-button
                      class="mr-1"
                      variant="danger"
                      @click="handleElimAfp(row.item)"
                      >&#10007;</b-button
                    >
                  </template>
                </b-table>
                <b-modal
                  :id="afpModal.id"
                  :title="afpModal.title"
                  @ok="handleGuardarAfp"
                  ok-title="Guardar"
                  ok-variant="success"
                >
                  <b-form-row>
                    <b-form-group
                      class="px-3"
                      id="id-group-nombre-afp"
                      label="Nombre"
                      label-for="wa_nombre"
                    >
                      <b-input
                        id="wa_nombre"
                        v-model="afpModal.content.wa_nombre"
                        type="text"
                        required
                        placeholder="Nombre"
                      ></b-input>
                    </b-form-group>
                    <b-form-group
                      id="id-group-porc-afp"
                      label="Porcentaje"
                      label-for="wa_porcentaje"
                    >
                      <b-input
                        id="wa_porcentaje"
                        v-model="afpModal.content.wa_porcentaje"
                        type="number"
                        required
                        placeholder="Porcentaje"
                      ></b-input>
                    </b-form-group>
                  </b-form-row>
                </b-modal>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Parametros" @click="getTab(2)">
            <b-row class="justify-content-center">
              <b-col class="col-auto">
                <b-table
                  show-empty
                  stripped
                  hover
                  bordered
                  stacked="md"
                  :items="items3"
                  :fields="fields3"
                  :sort-by.sync="sortBy"
                  head-variant="dark"
                >
                  <template v-slot:cell(action)="row">
                    <b-button
                      size="sm"
                      class="mr-1"
                      variant="info"
                      @click="showParam(row.item, row.index, $event.target)"
                      >Editar</b-button
                    >
                  </template>
                </b-table>
                <b-modal
                  :id="paramModal.id"
                  :title="paramModal.title"
                  @ok="handleGuardarParam"
                  ok-title="Guardar"
                  ok-variant="success"
                >
                  <b-form-row>
                    <b-form-group
                      class="px-3"
                      id="id-group-sueldo-minimo"
                      label="Sueldo Minimo"
                      label-for="wp_minimo"
                    >
                      <b-input
                        id="wp_minimo"
                        v-model="paramModal.content.wp_minimo"
                        type="text"
                        required
                        placeholder="Sueldo Minimo"
                      ></b-input>
                    </b-form-group>
                    <b-form-group
                      id="id-group-horas-semanales"
                      label="Horas Semanales"
                      label-for="wp_horassemanales"
                    >
                      <b-input
                        id="wp_horassemanales"
                        v-model="paramModal.content.wp_horassemanales"
                        type="number"
                        required
                        placeholder="Horas Semanales"
                      ></b-input>
                    </b-form-group>
                  </b-form-row>
                </b-modal>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Tramos">
            <p>I'm a disabled tab!</p>
          </b-tab>
          <b-tab title="Redirs" disabled>
            <p></p>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// import func from '../../vue-temp/vue-editor-bridge';
export default {
  name: "Home",
  props: {
    msg: String,
  },
  data() {
    return {
      fields: [
        { key: "wi_year", headerTitle: "Año", label: "Año" },
        {
          key: "wi_month",
          headerTitle: "Mes",
          label: "Mes",
          formatter: this.formatMonth,
        },
        {
          key: "wi_uf",
          headerTitle: "UF",
          label: "UF",
          formatter: (value) => {
            return new Intl.NumberFormat("es-CL", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(value);
          },
        },
        {
          key: "wi_utm",
          headerTitle: "UTM",
          label: "UTM",
          formatter: (value) => {
            return new Intl.NumberFormat().format(value);
          },
        },
        { key: "action", headerTitle: "...", label: "..." },
      ],
      fields2: [
        { key: "wa_nombre", headerTitle: "Nombre", label: "Nombre" },
        {
          key: "wa_porcentaje",
          headerTitle: "Porcentaje",
          label: "Porcentaje",
          formatter: (value) => {
            return (
              new Intl.NumberFormat("es-CL", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(value) + "%"
            );
          },
        },
        { key: "action", headerTitle: "", label: "" },
      ],
      fields3: [
        {
          key: "wp_minimo",
          headerTitle: "Sueldo Minimo",
          label: "Sueldo Minimo",
          formatter: (value) => {
            return new Intl.NumberFormat().format(value);
          },
        },
        {
          key: "wp_horassemanales",
          headerTitle: "Horas Semanales",
          label: "Horas Semanales",
        },
        {
          key: "wp_topeAfp",
          headerTitle: "Tope AFP",
          label: "Tope AFP",
          formatter: (value) => {
            return (
              new Intl.NumberFormat("es-CL", {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              }).format(value) + "UF"
            );
          },
        },
        {
          key: "wp_cesantia",
          headerTitle: "Seguro de Cesantía",
          label: "Seguro de Cesantía",
          formatter: (value) => {
            return (
              new Intl.NumberFormat("es-CL", {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              }).format(value) + "%"
            );
          },
        },
        {
          key: "wp_salud",
          headerTitle: "Cotización de Salud",
          label: "Cotización de Salud",
          formatter: (value) => {
            return (
              new Intl.NumberFormat("es-CL", {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              }).format(value) + "%"
            );
          },
        },
        {
          key: "wp_topeCesantia",
          headerTitle: "Tope Seguro de Cesantía",
          label: "Tope Seguro de Cesantía",
          formatter: (value) => {
            return (
              new Intl.NumberFormat("es-CL", {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
              }).format(value) + "UF"
            );
          },
        },
        { key: "action", headerTitle: "", label: "" },
      ],
      options: [
        "https://sa7qcspu9l.execute-api.us-east-1.amazonaws.com/api",
        "https://k7labs.ml/rest",
         "https://us-central1-misueldoapi.cloudfunctions.net"
      ],
      items: [],
      items2: [],
      items3: [],
      sortBy: "wi_month",
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      afpModal: {
        id: "afp-modal",
        title: "",
        content: "",
      },
      paramModal: {
        id: "param-modal",
        title: "",
        content: "",
      },
      selected: null,
      selectedYear: null,
      selectedMonth: null,
      selectedServer: "https://us-central1-misueldoapi.cloudfunctions.net",
      eliminar: null,
      anyos: [2023, 2022, 2021, 2020, 2019, 2018],
      meses: [
        { value: 1, text: "enero" },
        { value: 2, text: "febrero" },
        { value: 3, text: "marzo" },
        { value: 4, text: "abril" },
        { value: 5, text: "mayo" },
        { value: 6, text: "junio" },
        { value: 7, text: "julio" },
        { value: 8, text: "agosto" },
        { value: 9, text: "septiembre" },
        { value: 10, text: "octubre" },
        { value: 11, text: "noviembre" },
        { value: 12, text: "diciembre" },
      ],
      msj: "",
      show: null,
      dismissSecs: 3,
      dismissCountdown: 0,
      tabIndex: 0,
      tabs: ["#tab1", "#tab2"],
      baseUrl: null
    };
  },
  beforeUpdate() {},
  mounted() {
    // this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.hash);
    var hoy = new Date();
    var anyo = hoy.getFullYear();
    this.getUrl(this.selectedServer);
    this.getIndicadoresByYear(anyo);
    // const baseUrl = "https://us-central1-misueldoapi.cloudfunctions.net/";
    // this.$http.get(baseUrl + "/indicadores/" + this.selected).then(result => {
    //   // console.log(result);
    //   this.items = result.data.indicadores[0];
    // });
  },
  methods: {
    getTab(idx) {
      var hoy = new Date();
      var anyo = hoy.getFullYear();
      if (this.tabIndex == idx) {
        switch (idx) {
          case 0:
            this.getIndicadoresByYear(anyo);
            break;
          case 1:
            this.getAfps();
            break;
          case 2:
            this.getParams();
            break;
          default:
            break;
        }
      }
    },
    getUrl: function (item) {
      const baseUrl = item;
      this.baseUrl = baseUrl;
    },
    getIndicadoresByYear: function (anyo) {
      this.$http.get(this.baseUrl + "/indicadores/" + anyo).then((result) => {
        return (this.items = result.data.indicadores[0]);
      });
    },
    getAfps: function () {
      this.$http.get(this.baseUrl + "/afps").then((result) => {
        return (this.items2 = result.data.afp);
      });
    },
    getParams: function () {
      this.$http.get(this.baseUrl + "/params").then((result) => {
        return (this.items3 = result.data.params);
      });
    },
    showIndic(item, index, button) {
      this.infoModal.title = `Editar indicador: ${index}`;
      this.infoModal.content = item;
      this.selectedYear = item.wi_year;
      this.selectedMonth = item.wi_month;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    showAfp(item, index, button) {
      this.afpModal.title = `Editar Afp: ${index}`;
      this.afpModal.content = item;
      this.$root.$emit("bv::show::modal", this.afpModal.id, button);
    },
    showParam(item, index, button) {
      this.paramModal.title = `Editar Parametro: ${index}`;
      this.paramModal.content = item;
      this.$root.$emit("bv::show::modal", this.paramModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    resetAfpModal() {
      this.afpModal.title = "";
      this.afpModal.content = "";
    },
    formatMonth(value) {
      const mes = this.meses.find((e) => e.value == value);
      return mes.text;
    },
    handleGuardarIndic(BvModalEvt) {
      BvModalEvt.preventDefault();
      this.guardarIndic();
    },
    handleGuardarAfp(BvModalEvt) {
      BvModalEvt.preventDefault();
      this.guardarAfp();
    },
    handleGuardarParam(BvModalEvt) {
      BvModalEvt.preventDefault();
      this.guardarParam();
    },
    guardarIndic() {
      var data = {
        year: this.infoModal.content.wi_year,
        month: this.infoModal.content.wi_month,
        uf: this.infoModal.content.wi_uf,
        utm: this.infoModal.content.wi_utm,
        obs: "",
      };
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      };
      this.$http
        .post(this.baseUrl + "/guardar", data, { headers: headers })
        .then((response) => {
          this.toast(response.data.message, "success");
          this.$root.$emit("bv::hide::modal", this.infoModal.id);
        });
    },
    guardarAfp() {
      var data = {
        nombre: this.afpModal.content.wa_nombre,
        porcentaje: this.afpModal.content.wa_porcentaje,
        id: this.afpModal.content.Id,
      };
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      };
      this.$http
        .post(this.baseUrl + "/guardarafp", data, { headers: headers })
        .then((response) => {
          this.toast(response.data.message, "success");
          this.$root.$emit("bv::hide::modal", this.afpModal.id);
        });
    },
    guardarParam() {
      var data = {
        minimo: this.paramModal.content.wp_minimo,
        topeafp: this.paramModal.content.wp_topeAfp,
        id: this.paramModal.content.Id,
      };
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      };
      this.$http
        .post(this.baseUrl + "/guardarparam", data, { headers: headers })
        .then((response) => {
          this.toast(response.data.message, "success");
          this.$root.$emit("bv::hide::modal", this.paramModal.id);
        });
    },
    elimIndic(item) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm(
          `¿Está seguro que desea eliminar este registro [${item.wi_month}/${item.wi_year}]?`,
          {
            title: "Eliminación",
            size: "sm",
            okVariant: "danger",
            okTitle: "Eliminar",
            cancelTitle: "Cancelar",
          }
        )
        .then((value) => {
          if (value) {
            this.eliminarIndic(item);
          }
        })
        .catch((err) => {
          this.console.log(err);
        });
    },
    handleElimAfp(item) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm(
          `¿Está seguro que desea eliminar este registro [${item.wa_nombre}]?`,
          {
            title: "Eliminación",
            size: "sm",
            okVariant: "danger",
            okTitle: "Eliminar",
            cancelTitle: "Cancelar",
          }
        )
        .then((value) => {
          if (value) {
            this.eliminarAfp(item);
          }
        })
        .catch((err) => {
          this.console.log(err);
        });
    },
    eliminarIndic(indic) {
      this.$http
        .get(
          this.baseUrl + "/eliminar/" + indic.wi_year + "/" + indic.wi_month
        )
        .then((response) => {
          this.toast(response.data.message, "danger");
        });
    },
    eliminarAfp(afp) {
      this.$http
        .get(this.baseUrl + "/eliminarafp" + afp.id)
        .then((response) => {
          this.toast(response.data.message, "danger");
        });
    },
    toast(message, variant, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${this.msg}`,
        toaster: "b-toaster-top-center",
        solid: true,
        variant: variant,
        "auto-hide-delay": 3000,
        appendToast: append,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
